<script setup lang="ts">
import contactIcon from './asset/contact.svg'
import telegramIcon from './asset/telegram.svg'
import whatsappIcon from './asset/whatsapp.svg'
import { useSocialsStore } from '~/store/socials'

const socialsStore = useSocialsStore()
const contacts = ref([
  {
    image: contactIcon,
    title: `${socialsStore.phone}`,
    href: `tel:${socialsStore.phone}`,
  },
  {
    image: telegramIcon,
    title: 'Написать Телеграм',
    href: socialsStore.telegram,
  },
  {
    image: whatsappIcon,
    title: 'Написать WhatsApp',
    href: socialsStore.whatsApp,
  },
])
</script>

<template>
  <div class="service-unavailable">
    <img
      src="./asset/unavailable-background-mobile.svg"
      alt="Сервис недоступен"
      class="service-unavailable__image service-unavailable__image_mobile"
    >
    <img
      src="./asset/unavailable-background.svg"
      alt="Сервис недоступен"
      class="service-unavailable__image service-unavailable__image_desktop"
    >
    <p class="service-unavailable__title">
      Ведутся технические работы
    </p>
    <p class="service-unavailable__description">
      Близко временно не принимает заказы. Если у вас есть вопросы, вы можете связаться с нами
    </p>
    <div class="service-unavailable__contact-block">
      <a
        v-for="contact in contacts"
        :key="contact.href"
        :href="contact.href"
        class="service-unavailable__contact"
      >
        <img
          :src="contact.image"
          :alt="contact.title"
          class="service-unavailable__icon"
        >
        {{ contact.title }}
      </a>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.service-unavailable {
  padding: 10px 15px 18px;

  &__icon {
    margin-right: 6px;
  }

  &__title {
    width: 85%;
    margin-bottom: 12px;
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
  }

  &__description {
    width: 80%;
    font-size: 16px;
    line-height: 22px;
    color: #818181;
  }

  &__contact {
    display: flex;
    justify-content: center;
    padding: 12px;
    margin-bottom: 12px;
    font-size: 16px;
    font-weight: 500;
    color: #000;
    text-decoration: none;
    border: 1px solid #E5E5E5;
    border-radius: 6px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__contact-block {
    display: flex;
    flex-direction: column;
    margin-top: 28px;
  }

  &__image {

    &_mobile {
      display: flex;
      width: 100%;
      max-width: 460px;
      margin: 0 auto 12px;
    }

    &_desktop {
      display: none;
    }
  }

  @media (min-width: 1000px) {
    max-width: 960px;
    padding: 100px 0 0;
    margin: 0 auto;

    &__title {
      width: auto;
      font-size: 40px;
      line-height: 54px;
      text-align: center;
    }

    &__description {
      margin: 0 auto;
      font-size: 24px;
      line-height: 32px;
      text-align: center;
    }

    &__contact {
      width: 100%;
      margin-right: 12px;
      margin-bottom: 0;

      &:last-child {
        margin-right: 0;
      }
    }

    &__contact-block {
      flex-direction: row;
      margin: 28px auto 0;
    }

    &__contact-block, &__description {
      max-width: 630px;
    }

    &__image {

      &_desktop {
        display: block;
        margin-bottom: 40px;
      }

      &_mobile {
        display: none;
      }
    }
  }
}
</style>
